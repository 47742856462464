//
// Modal
//
.modal{
    
    @media screen and (min-width: 900px) {
        height: 90%;
      }
}

// Fluid modal

.modal-fluid {
    .modal-dialog {
        margin-top: 0;
        margin-bottom: 0;
    }
    .modal-content {
        border-radius: 0;
    }
}


// Background color variations

@each $color, $value in $theme-colors {
    .modal-#{$color} {
        @include modal-variant($value);
    }
}
