.navbar .collapsing .navbar-toggler{
  pointer-events: none;
}
.navbar .nav-item .nav-link-icon.nav-link i{
  margin-right: 4px;
}


.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
  color: rgba(0,0,0,.9);
  background-color: #0710522e;
}


@media (min-width: 768px) {
.navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
    top: 0.25rem;
    bottom: 0.25rem;
    left: 0;
    right: auto;
    border-left: 6px solid #5e72e4;
    border-bottom: 0;
}
}