//
// Profile card
//

.card-profile-image {
    position: relative;

    img {
        max-width: 180px;
        border-radius: $border-radius;
        @extend .shadow;
        transform: translate(-50%,-30%);
        position: absolute;
        left: 50%;
        transition: $transition-base;

        &:hover {
            transform: translate(-50%, -33%);
        }
    }
}

.card-profile-stats {
    padding: 1rem 0;

    > div {
        text-align: center;
        margin-right: 1rem;
        padding: .875rem;

        &:last-child {
            margin-right: 0;
        }

        .heading {
            font-size: 1.1rem;
            font-weight: bold;
            display: block;
        }
        .description {
            font-size: .875rem;
            color: $gray-500;
        }
    }
}

.card-profile-actions {
    padding: .875rem;
}


.DayPickerInput-Overlay{
    z-index: 999 !important;
}