//
// Card
//


.card-translucent {
    background-color: rgba(18, 91, 152, 0.08);
}




.title-card-desktop{
    display: block !important;
}

.title-card-mobile{
    display: none !important;
}


@media (max-width:801px)  {
    .title-card-desktop{
        display: none !important;
    }


    .title-card-mobile{
        display: block !important;
    }

    body{
        background-image: none !important;
    }
}
